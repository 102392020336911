<script setup lang="ts">
const config = useSiteConfig()
const { t } = useI18n()
</script>

<template>
   <footer class="bg-white p-12">
      <div class="grid justify-between gap-8 lg:grid-cols-3">
         <div class="text-sm">
            <img
               src="/logos/iucn_en.png"
               alt="IUCN"
               format="webp"
               width="451"
               height="251"
               class="h-24 w-auto"
            >
            <p class="mt-4 font-medium">
               {{ t('global.description') }}
            </p>
         </div>

         <div class="flex flex-col gap-2">
            <p class="mb-4 text-sm font-medium">
               {{ t('global.partners') }}
            </p>
            <div class="flex flex-wrap items-center gap-4 *:h-12 *:w-auto">
               <img
                  src="/logos/KBA.svg"
                  alt="Key Biodiversity Areas"
                  format="webp"
                  width="1320"
                  height="800"
               >
               <img
                  src="/logos/protected-planet.png"
                  alt="Protected Planet"
                  format="webp"
                  width="6206"
                  height="2220"
               >
               <img
                  src="/logos/red-list.png"
                  alt="Red List"
                  format="webp"
                  width="1101"
                  height="1024"
               >
               <img
                  src="/logos/restoration-barometer.svg"
                  alt="Restoration Barometer"
                  format="webp"
                  width="1045.21"
                  height="523.33"
               >
            </div>
         </div>

         <div class="flex flex-col gap-2 [&_a]:underline">
            <p class="mb-4 text-sm font-medium">
               {{ t('global.navigation') }}
            </p>
            <NuxtLinkLocale :to="{ name: 'index' }">
               {{ t('global.home') }}
            </NuxtLinkLocale>
            <NuxtLinkLocale :to="{ name: 'faq' }">
               {{ t('global.faq') }}
            </NuxtLinkLocale>
            <NuxtLinkLocale :to="{ name: 'guidance-data-entry' }">
               {{ t('global.guidance-data-entry') }}
            </NuxtLinkLocale>
            <NuxtLinkLocale :to="{ name: 'guidance-data-validation' }">
               {{ t('global.guidance-data-validation') }}
            </NuxtLinkLocale>
            <NuxtLinkLocale :to="{ name: 'terms-and-conditions' }">
               {{ t('global.terms-and-conditions') }}
            </NuxtLinkLocale>
         </div>
      </div>

      <p class="mt-12 text-center">
         &copy; {{ config.name }} {{ new Date().getFullYear() }}
      </p>
   </footer>
</template>
